<template lang="pug">
  div
    //- チェックインガイドモーダル
    CheckinGuide
    .container-fluid
      .wrapper        
        //-岩場参照履歴
        .spinner
          b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isRecentRockyListLoading")
        div.text-center.my-5(v-if="falseInfo") 情報の取得に失敗しました
        #latest-record(v-if="isLogin & latestRecordList.length > 0 && !falseInfo")
          .header-title(v-if="!isRecentRockyListLoading") 最近見た岩場 
            //-最近見た岩場の削除
            i.far.fa-trash-alt(@click="restRecentRockyList()")
          carousel(v-bind:per-page-custom=[[100,3],[768,4]] :autoplay="false" :navigation-enabled="false" :pagination-enabled="false" navigation-prev-label="〈" navigation-next-label="〉" :speed="1000")
            slide(v-for="rocky in this.latestRecordList")
              .thumbnail-wrapper(@click="callRockyRecord(rocky.rockyId)")
                img.slide-img(:src="getRockyImgThumbPath(rocky.imgPath)" :alt="rocky.rockyName")
                .thumbnail-info
                  .rocky-nameKana {{ rocky.nameKana }}
                  .rocky-name {{rocky.rockyName}}
        //-ランディング(未ログイン時)
        #landing(v-if="!isLogin")
          carousel(v-bind:per-page-custom=[[100,1],[768,1]] :autoplay="true" :loop="true" :autoplayTimeout="5000" :navigation-enabled="false" :pagination-enabled="false" navigation-prev-label="〈" navigation-next-label="〉" :speed="1000")
            slide(v-for="rocky in this.landingImgList")
              .thumbnail-wrapper
                img.slide-img(:src="rocky.imgPath" :alt="rocky.rockyName" @click="callRockyRecord(rocky.rockyId)")
                .thumbnail-info
                  .info-main(v-html="rocky.infoMain")
                  .info-sub {{rocky.infoSub}}
                  .link-create-user
                    button.info-btn(@click="callRouterInfo('/register_user/send_auth')") 
                      | 新規ユーザー登録
                  .link-site-info
                    button.info-btn(@click="callRouterInfo('info/user_guide')") 
                      | ヤマラボの楽しみ方

      .wrapper#map-wrapper
        #top-page-map.position-relative
          Map(:isSearchCtlShow="true",:isCustumExpandCtlShow="true",:isfullscreenControlShow="false")

      .wrapper
        //-登攀記録
        .spinner
          b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isClimbCountLoading")
        #climbing-count(v-if="isLogin && !isClimbCountLoading")
          div.text-center.my-5(v-if="falseInfo") 情報の取得に失敗しました
          #climbing-count-wrapper(@click="callRouterPush()" v-if="!falseInfo")
            .div
              .headline 登攀記録
              .detail
                .inner
                  .title 総ルート
                  .count-ranking
                    .count {{climbingCount.climbAllRoute}}
                    .ranking {{ranking.climbAllRouteRank > 0 && climbingCount.climbAllRoute > 0 ? ranking.climbAllRouteRank : ' - ' }}位
                .inner
                  .title 完登ルート
                  .count-ranking
                    .count {{climbingCount.climbCompleteRoute}}
                    .ranking {{ranking.climbCompleteRouteRank  > 0 && climbingCount.climbCompleteRoute > 0  ? ranking.climbCompleteRouteRank : ' - ' }}位
            .div
              .headline チェックイン記録
              .detail 
                .inner
                  .title 都道府県
                  .count-ranking
                    .count {{checkinCount.prefectures}}
                    .ranking {{ranking.checkinPrefecturesRank  > 0 && checkinCount.prefectures > 0  ? ranking.checkinPrefecturesRank : ' - ' }}位
                .inner
                  .title 岩場
                  .count-ranking
                    .count {{checkinCount.rocky}}
                    .ranking {{ranking.checkinRockyRank  > 0 && checkinCount.rocky > 0  ? ranking.checkinRockyRank : ' - ' }}位
                .inner
                  .title 岩
                  .count-ranking
                    .count {{checkinCount.rock}}
                    .ranking {{ranking.checkinRockRank  > 0 && checkinCount.rock > 0  ? ranking.checkinRockRank : ' - ' }}位
        //-岩場検索(未ログイン時)
        #rocky-search(v-else-if="!isLogin")
          #rocky-search-wrapper
            router-link(to="/rockyInfo/" + process.env.VUE_APP_DEMO_ROCKY_ID)
              p ※岩場情報のサンプルを表示する
            b-button.button-blue#rocky-search-button(@click="callSearchView()")
              span
                i.fas.fa-search
              |&nbsp;岩場を探す

        //-ヤマラボブログ
        #yamalab-blog.b-block
          .row.no-gutters
            .col-6.col-md-7.text-white.banner-title.pl-1.pl-md-3
              .banner-text1 管理人ブログ
              .banner-text2.mt-1 トポの追加や、岩場の最新情報をお届け
            .col-6.col-md-5.banner-box.p-2
              div.my-md-2.my-1(v-for="item in blogData")
                a(:href="getBlogLink(item.date.replace(/-/g, ''))" target="_blank" rel="noopener")
                  .d-flex
                    div
                      img.blog-rocky-img(:src="makeImgPath(item.imagePath)")
                    .text-white.pl-1.banner-text5.flex-grow-1
                      .banner-text3  
                        .banner-text5.text-white {{item.title}}
                      .banner-text4
                        div {{item.date.replace(/-/g, '/')}}
              .text-right.banner-text3
                a.text-white(href="/blog" target="_blank" rel="noopener") more &gt;
              
        //-お勧めエリア
        #recommend
          .header-title おすすめ岩場
          carousel(v-bind:per-page-custom=[[100,3],[768,4]] :autoplay="false" :navigation-enabled="false" :pagination-enabled="false" navigation-prev-label="〈" navigation-next-label="〉" :speed="1000")
            slide(v-for="rocky in this.recommendList")
              .thumbnail-wrapper(@click="callRockyInfo(rocky.rockyId)")
                  img.slide-img(:src="getRockyImgThumbPath(rocky.imgPath)" :alt="rocky.rockyName")
                  .thumbnail-info
                    .rocky-nameKana {{ rocky.nameKana }}
                    .rocky-name {{rocky.rockyName}}

</template>

<script>
import Map from '@/components/Map.vue'
import CheckinGuide from '@/components/guide/checkin_guide'

import { Carousel, Slide } from 'vue-carousel'
import axios from 'axios'

export default {
  // データオブジェクト
  props: {
  },
  data() {
    return {
      isLogin: this.$user.isLogin,
      pageAllData: null,
      isFriendRequestModalShow:false,
      latestRecordList: [],
      recommendList: [
        {'rockyId': 14, 'rockyName' : '御岳ボルダー', 'imgPath' : this.makeImgPath('/img/rp/0014_001.jpg'), 'nameKana' : 'みたけぼるだー'},
        {'rockyId': 204, 'rockyName' : '笠置山[大岩ごけエリア]', 'imgPath' : this.makeImgPath('/img/rp/0204_001.jpg'), 'nameKana' : 'かさぎやま'},
        {'rockyId': 79, 'rockyName' : '豊田[大田エリア]', 'imgPath' :this.makeImgPath('/img/rp/0079_001.jpg'), 'nameKana' : 'とよた'},
        {'rockyId': 214, 'rockyName' : '王子ヶ岳[ニコニコ岩周辺]', 'imgPath' : this.makeImgPath('/img/rp/0214_001.jpg'), 'nameKana' : 'おうじがたけ'}
      ],
      climbingCount: null,
      checkinCount: null,
      ranking: null,
      landingImgList: [
        {'infoMain':'<span style="color:#FFC400; font-size:1.7rem;">外岩</span>に登りに行こう！','infoSub':'クライミング記録を集めよう！','imgPath':this.makeImgPath('/img/top/slide_1.jpg')},
        {'infoMain':'国内の<span style="color:#fd8602; font-size:1.7rem;">岩場</span>を検索できる</p>','infoSub':'','imgPath':this.makeImgPath('/img/top/slide_2.jpg')},
        {'infoMain':'岩場近くの<br/><span style="color:#b5fdb3;" font-size:1.7rem;>コンビニ・温泉情報</span>を掲載','infoSub':'','imgPath':this.makeImgPath('/img/top/slide_3.jpg')},
        {'infoMain':'<span style="color:#14c6d3; font-size:1.7rem;">トポ（ルート）</span>を<br/>確認できる','infoSub':'','imgPath':this.makeImgPath('/img/top/slide_4.jpg')},
        {'infoMain':'外岩クライミングを<br/><span style="color:#8dcafc; font-size:1.7rem;">記録</span>できる','infoSub':'','imgPath':this.makeImgPath('/img/top/slide_5.jpg')},
      ],
      //Loading表示用(isLogin=trueの場合true)
      isRecentRockyListLoading:this.$user.isLogin,
      isClimbCountLoading:this.$user.isLogin,
      allRockyJsonData:[],
      isExpanded:false,
      falseInfo: false,
      blogData:[],
    }
  },
  created() {
    this.createdProcess()

    this.getBlog()
  },
  destroyed(){
    // MAPを最大表示している状態でヘッダーナビから遷移する場合の対策
    // PCでのスクロール禁止解除
    document.removeEventListener("mousewheel", this.scroll_control, { passive: false });
    // スマホでのタッチ操作でのスクロール禁止解除
    document.removeEventListener('touchmove', this.scroll_control, { passive: false });
  },
  // メソッド
  methods: {
    // ブログ記事データ取得
    getBlog() {
      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-blog', {
        params: {
          "type": "banner",
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.blogData = res.data
      })
      .catch(err => {
        console.log(err)
      })
    },

    getBlogLink(path) {
      return "/blog/" + path
    },
    
    async createdProcess(){
      //ログイン時のみ取得
      if(this.isLogin){
        //登攀件数取得
        await this.getUserClimbingCheckinCount()
        //ランキング取得
        await this.getUserRanking()
        //Loading解除
        this.isClimbCountLoading = false

        //全岩場JSON
        await this.getAllRockyJson()
        //ユーザー情報(最近見た岩場)
        this.getUserInfo()
      }
    },
    //-------------------------------------------
    //トップページ上でチェックインされた場合の処理
    //-------------------------------------------
    reloadMyPage(){
      //チェックイン件数をリロードする
      this.getUserClimbingCheckinCount()
    },
    //------------------------
    //全岩場JSON取得
    //------------------------
    async getAllRockyJson(){
      //岩場情報JSON
      let jsonUrl = process.env.VUE_APP_MATERIAL_URL + '/allRockyList.json'
      await axios
        .get(jsonUrl)
        .then(response => {
          this.allRockyJsonData = response.data
        })
        .catch(err => {
          this.falseInfo = true
          this.isRecentRockyListLoading = false
          this.isClimbCountLoading = false
        })

      //データが0件の場合
      if(this.allRockyJsonData.length == 0){
        //ここでデータが取得できない場合は、データ不正
        await this.asyncDialog('システムエラー：岩場情報を取得できませんでした')
      　return
      }
    },

    //------------------------
    //ユーザー情報取得
    //------------------------
    async getUserInfo() {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info'
      await axios
        .get(jsonUrl,{
          params: {},
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          //最近見た岩場
          let wkRockyIdList =  response.data[0].recentRockyIdList
          wkRockyIdList.forEach((rockyId) => {

            //allRockyList.jsonから岩場名を取得する
            let rockyJsonData = this.allRockyJsonData.filter(record => record.rockyId == rockyId)

            let wkObj = {
              rockyId : rockyId,
              imgPath : rockyJsonData[0].imgPath,
              rockyName : rockyJsonData[0].name,
              nameKana : rockyJsonData[0].nameKana
            }

            this.latestRecordList.push(wkObj)
          })

          //描画フラグ
          this.isRecentRockyListLoading = false

        })
        .catch(err => {
          this.falseInfo = true
          this.isRecentRockyListLoading = false
          this.isClimbCountLoading = false
        })
    },
    //------------------------
    //登攀件数取得
    //------------------------ 
    async getUserClimbingCheckinCount(){
      let jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/user-climbing-checkin-count'

      await axios
        .get(jsonUrl,{
          params: {
            'userId': this.$user.userId
          },         
          headers: {
            'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
          }
        })
        .then(response => {
          let result = response.data
          //登攀件数
          this.climbingCount = result.climbingCount
          //チェックイン件数
          this.checkinCount = result.checkinCount

        })
        .catch(err => {
          this.falseInfo = true
          this.isRecentRockyListLoading = false
          this.isClimbCountLoading = false
        })
    },
    //------------------------
    //ランキング取得
    //------------------------ 
    async getUserRanking(){
      let jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/user-ranking'

      await axios
        .get(jsonUrl,{
          params: {
            'userId': this.$user.userId
          },         
          headers: {
            'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
          }
        })
        .then(response => {
          let result = response.data
          //ランキング
          this.ranking = result.ranking
        })
        .catch(err => {
          this.falseInfo = true
          this.isRecentRockyListLoading = false
          this.isClimbCountLoading = false
        })
    },
    //------------------------
    //最近見た岩場のリセット
    //------------------------
    async restRecentRockyList(){
      
      const message = '最近見た岩場をクリアしますか？'
      const answer1 = 'クリアする'
      const answer2 = 'キャンセル'
      if(!await this.asyncDialogAnswer(message,answer1,answer2)){
        return
      }

      //API実行
      const url = process.env.VUE_APP_API_GATEWAY + '/api-user-info'
      const params = {
        rockyId : this.rockyId,
        resetFlg : 'true'
      }
      //更新実行
      try {
        await axios.patch(url, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
      } catch(err) {
        alert('岩場参照履歴をクリアできませんでした。')
        return
      }

      //App.vueのunReadNoticeを更新する。
      this.latestRecordList = []

    },
    asyncDialogAnswer(message,answer1,answer2) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxConfirm(message, {
          title: '確認',
          okVariant: 'success',
          okTitle: answer1,
          cancelTitle: answer2,
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          resolve(value)
        })
      })
    },
    callRouterPush(){
      this.$router.push('/myPage/' + this.$user.userId +'/userInfoTop')
    },
    callRouterInfo(path){
      this.$router.push(path)
    },
    callSearchView(){
      this.$router.push('/rocky/search')
    },
    callRockyInfo(rockyId){
      //TOP画面のMAPの中央位置とzoomの保持を解除する
      localStorage.isLastMapCentervalid = false
      this.$router.push('/rockyInfo/' + rockyId)
    },
    callRockyRecord(rockyId){
      //サンプル画像以外の場合遷移する
      if(rockyId!=''){
        //TOP画面のMAPの中央位置とzoomの保持を解除する
        localStorage.isLastMapCentervalid = false
        this.$router.push('/rockyInfo/' + rockyId)
      }
    },
    makeImgPath(imgPath){
      return process.env.VUE_APP_MATERIAL_URL + imgPath
    },
    getRockyImgThumbPath(imgPath){
      return this.getRockyThumbPath(imgPath,'360')
    },
    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    //mapを拡大する
    mapFull(){
      this.isExpanded = true

      let wrapperEl = document.getElementById('map-wrapper')
      wrapperEl.style.width = '100%'
      wrapperEl.style.overflow = 'hidden'
      wrapperEl.style.paddingLeft = '0'
      wrapperEl.style.paddingRight = '0'
      wrapperEl.style.overflow = 'hidden'

      let mapEl = document.getElementById('top-page-map')
      var y = mapEl.getBoundingClientRect().top

      const ua = navigator.userAgent;
      if(ua.indexOf('Trident') !== -1) {
        //IE 11の場合はナビがstickyにならないので、上いっぱいまで表示する。
        window.scrollBy(0,y)
      }else{
        window.scrollBy(0,y-62)
      }

      mapEl.style.zIndex = '1'
      mapEl.style.width = '100vw'

      let buttonEl = document.getElementById('expand-button')


      if(window.innerWidth< 414){
        mapEl.style.height = '80vh' 
      }else{
        buttonEl.style.right = '2rem'
        mapEl.style.height = '100vh'        
      }

      // PCでのスクロール禁止
      document.addEventListener("mousewheel", this.scroll_control, { passive: false });
      // スマホでのタッチ操作でのスクロール禁止
      document.addEventListener("touchmove", this.scroll_control, { passive: false });
    },
    //mapを元に戻す
    mapFullReset(){
      this.isExpanded = false

      let wrapperEl = document.getElementById('map-wrapper')
      wrapperEl.style.width = ''
      wrapperEl.style.overflow = ''
      wrapperEl.style.paddingLeft = ''
      wrapperEl.style.paddingRight = ''
      wrapperEl.style.overflow = ''

      let mapEl = document.getElementById('top-page-map')
      mapEl.style.height = ''
      mapEl.style.zIndex = ''
      mapEl.style.width = ''

      let buttonEl = document.getElementById('expand-button')
      buttonEl.style.right = '0.5rem'

      // PCでのスクロール禁止解除
      document.removeEventListener("mousewheel", this.scroll_control, { passive: false });
      // スマホでのタッチ操作でのスクロール禁止解除
      document.removeEventListener('touchmove', this.scroll_control, { passive: false });
    },
    scroll_control(event) {
      event.preventDefault();
    }
  },
  // コンポーネント
  components: {
    Carousel,
    Slide,
    Map,
    CheckinGuide
  }
}
</script>

<style lang="stylus" scoped>
#app
.container-fluid
  padding-left 0px
  padding-right 0px
.wrapper
  padding-left 0px
  padding-right 0px
.header-title
  font-size 1.3rem
  line-height 1.3rem
  margin 0.5rem 0
  position relative
  i
    position absolute
    right 1rem
    top 0.3rem
    font-size 1rem
    color #adb4b9
    cursor pointer

//コンテナ群
#friend-request
  display flex
  //右寄せ
  justify-content flex-end
  padding-top 2rem
  color #eceeef
  text-align right
  padding-right 0.5rem
  font-size 0.8rem
  color #5aa7de
  #friend-link
    cursor pointer
    i  
      font-weight 600
      text-decoration underline

#latest-record
  padding 10px 0 20px 0

//岩場検索
#rocky-search-wrapper
  padding-left 5%
  padding-right 5%
//スライダー（ランディング以外）
.VueCarousel-slide
  align-items center
  justify-content center
  border-right 3px solid #FFF
  box-sizing border-box
.thumbnail-wrapper
  position relative
.thumbnail-wrapper:hover
  opacity 0.7
  transition-duration 0.3s
.slide-img,.thumbnail-info
  width 100%
  cursor pointer
.slide-img
  height 100px
  object-fit cover
.thumbnail-info
  height 45%
  position absolute
  bottom 0
  background-color rgba(0, 0, 0, 0.5)
  .climbing-date
    padding-left 0.5rem
    font-size 0.7rem
    color #d2d3d4
  .rocky-name
    color white
    padding-left 0.5rem
    font-size 0.8rem
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  .rocky-nameKana
    color white
    padding-left 0.5rem
    font-size 0.4rem
    overflow hidden
    text-overflow ellipsis
    white-space nowrap
  .recommend-rocky-name
    padding-left 0.5rem
    font-size 0.8rem
    color white
    overflow hidden
    text-overflow ellipsis
    white-space nowrap

//スライダー（ランディング）
#landing
  .thumbnail-wrapper
    position relative
    .slide-img,.thumbnail-info
      width 100%
      height 200px
      cursor default
    .slide-img
      object-fit cover
    .thumbnail-info
      position absolute
      background-color rgba(0, 0, 0, 0.4)
      text-align center
      .info-main,.info-sub,.link-create-user,.link-site-info
        width 100%
        position absolute
        color #FFF
      .info-main,.info-sub
        font-weight 700
      .info-main
        top 55px
        font-size 1.3rem
        letter-spacing 2.6px
      .info-sub
        top 90px
      .link-create-user,.link-site-info
        text-decoration-line underline
        text-align right
        font-size 0.8rem
        padding-right 0.8rem    
        a 
          color white
      .link-create-user
        top 20px
        display none      
      .link-site-info
        top 160px

.info-btn
  border solid 1px #fff
  border-radius 3px
  background rgba(0,0,0,0.2)
  color #FFF
  cursor pointer
.info-btn:hover
  background rgba(0,0,0,0.8)

.spinner
  text-align center

//登攀件数
#climbing-count-wrapper
  margin-bottom 1rem
  cursor pointer
  .headline
    font-size 0.8rem
    background #004170
    color #FFF
    padding-left 0.5rem
  .detail
    display flex
    .inner
      border 0.5px #cfd3d7 solid
      width 50%
      padding 0 0.5rem
      .title
        font-size 0.6rem
        font-weight 500
      .count-ranking
        display flex
        align-items center
        justify-content center  
        .count
          color #006fbe
          font-weight 700        
        .ranking
          margin-left 0.7rem
          background #dee1e3
          color #606e79
          font-size 0.6rem  
          padding 0 1rem
          border-radius 10%

#rocky-search
  padding-top 20px
  padding-bottom 20px
#recommend
  padding-bottom 15px

//地図
#top-page-map
  width 100%
  height 280px

//ヤマラボブログ
#yamalab-blog
  background-image: url(/img/yamalab_blog.jpeg);
  background-repeat: no-repeat
  background-size: cover
  height 120px
.banner-box
  z-index 2
  background rgba(0, 0, 0, 0.5)
  display inline-block
  height 120px
.blog-rocky-img
  width 60px
  height 40px
  font-size .7rem
.banner-text2
  font-size .5rem
.banner-text3
  font-size 10px
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
.banner-text4
  font-size 10px
  line-height 1
.banner-title
  padding-top 35px
.banner-text5
  text-overflow ellipsis
  white-space nowrap
  overflow hidden

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px)
  .thumbnail-info
    height 32%
    .climbing-date
      padding-left 1rem
      font-size 1rem
    .rocky-name
      padding-left 1rem
      font-size 1.2rem
    .rocky-nameKana
      padding-left 1rem
      font-size .7rem
    .recommend-rocky-name
      padding-left 1rem
      padding-top 0.5rem
      color white
  .slide-img
    height 220px
  #top-page-map
    height 400px
  #landing
    .thumbnail-wrapper
      .slide-img,.thumbnail-info
        height 350px
      .thumbnail-info
        .link-create-user
          display block
        .info-main
          font-size 1.5rem
          top 125px
        .info-sub
          font-size 1.2rem
          top 160px
        .link-create-user
          top 20px
        .link-site-info
          top 300px
  //ヤマラボブログ
  .blog-rocky-img
    object-fit cover
    width 100px
    height 60px
  #yamalab-blog
    height 177px
  .banner-box
    height 177px
  .banner-title
    padding-top 60px
  .banner-text1
    font-size 1.4rem
  .banner-text2
    font-size .7rem
  .banner-text3
    font-size .8rem
  .banner-text4
    padding-top 3px
    font-size 12px
    line-height 1.1

// 大デバイス（デスクトップ, 1170px 以上）
@media (min-width 1170px)
  #app
    .wrapper
      background #FFF
  #friend-request
    color #006fbe
  //ヤマラボブログ
  .blog-rocky-img
    width 130px
    height 80px
  #yamalab-blog
    height 220px
  .banner-box
    height 220px
  .banner-title
    padding-top 70px
  .banner-text1
    font-size 1.8rem
  .banner-text2
    font-size 1rem
  .banner-text3
    font-size 1.1rem
  .banner-text4
    padding-top 5px
    font-size 15px
    line-height 1.1

//IE11且つPC表示の場合
@media screen and (-ms-high-contrast: active) and (min-width: 1120px), screen and (-ms-high-contrast: none)  and (min-width: 1120px)
  #landing
    .thumbnail-wrapper
      .slide-img,.thumbnail-info
        height 450px
      .thumbnail-info
        .info-main
          font-size 1.5rem
          top 175px
        .info-sub
          font-size 1.2rem
          top 160px
        .link-create-user
          top 40px
          right 30px
        .link-site-info
          top 380px
          right 30px

</style>