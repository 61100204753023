var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CheckinGuide"),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "spinner" },
            [
              _vm.isRecentRockyListLoading
                ? _c("b-spinner", {
                    staticClass: "mt-5 mb-5",
                    attrs: { variant: "primary", label: "Loading..." }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.falseInfo
            ? _c("div", { staticClass: "text-center my-5" }, [
                _vm._v("情報の取得に失敗しました")
              ])
            : _vm._e(),
          _vm.isLogin & (_vm.latestRecordList.length > 0) && !_vm.falseInfo
            ? _c(
                "div",
                { attrs: { id: "latest-record" } },
                [
                  !_vm.isRecentRockyListLoading
                    ? _c("div", { staticClass: "header-title" }, [
                        _vm._v("最近見た岩場 "),
                        _c("i", {
                          staticClass: "far fa-trash-alt",
                          on: {
                            click: function($event) {
                              return _vm.restRecentRockyList()
                            }
                          }
                        })
                      ])
                    : _vm._e(),
                  _c(
                    "carousel",
                    {
                      attrs: {
                        "per-page-custom": [[100, 3], [768, 4]],
                        autoplay: false,
                        "navigation-enabled": false,
                        "pagination-enabled": false,
                        "navigation-prev-label": "〈",
                        "navigation-next-label": "〉",
                        speed: 1000
                      }
                    },
                    _vm._l(this.latestRecordList, function(rocky) {
                      return _c("slide", [
                        _c(
                          "div",
                          {
                            staticClass: "thumbnail-wrapper",
                            on: {
                              click: function($event) {
                                return _vm.callRockyRecord(rocky.rockyId)
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "slide-img",
                              attrs: {
                                src: _vm.getRockyImgThumbPath(rocky.imgPath),
                                alt: rocky.rockyName
                              }
                            }),
                            _c("div", { staticClass: "thumbnail-info" }, [
                              _c("div", { staticClass: "rocky-nameKana" }, [
                                _vm._v(_vm._s(rocky.nameKana))
                              ]),
                              _c("div", { staticClass: "rocky-name" }, [
                                _vm._v(_vm._s(rocky.rockyName))
                              ])
                            ])
                          ]
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isLogin
            ? _c(
                "div",
                { attrs: { id: "landing" } },
                [
                  _c(
                    "carousel",
                    {
                      attrs: {
                        "per-page-custom": [[100, 1], [768, 1]],
                        autoplay: true,
                        loop: true,
                        autoplayTimeout: 5000,
                        "navigation-enabled": false,
                        "pagination-enabled": false,
                        "navigation-prev-label": "〈",
                        "navigation-next-label": "〉",
                        speed: 1000
                      }
                    },
                    _vm._l(this.landingImgList, function(rocky) {
                      return _c("slide", [
                        _c("div", { staticClass: "thumbnail-wrapper" }, [
                          _c("img", {
                            staticClass: "slide-img",
                            attrs: { src: rocky.imgPath, alt: rocky.rockyName },
                            on: {
                              click: function($event) {
                                return _vm.callRockyRecord(rocky.rockyId)
                              }
                            }
                          }),
                          _c("div", { staticClass: "thumbnail-info" }, [
                            _c("div", {
                              staticClass: "info-main",
                              domProps: { innerHTML: _vm._s(rocky.infoMain) }
                            }),
                            _c("div", { staticClass: "info-sub" }, [
                              _vm._v(_vm._s(rocky.infoSub))
                            ]),
                            _c("div", { staticClass: "link-create-user" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "info-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.callRouterInfo(
                                        "/register_user/send_auth"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" 新規ユーザー登録")]
                              )
                            ]),
                            _c("div", { staticClass: "link-site-info" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "info-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.callRouterInfo(
                                        "info/user_guide"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" ヤマラボの楽しみ方")]
                              )
                            ])
                          ])
                        ])
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "wrapper", attrs: { id: "map-wrapper" } }, [
          _c(
            "div",
            { staticClass: "position-relative", attrs: { id: "top-page-map" } },
            [
              _c("Map", {
                attrs: {
                  isSearchCtlShow: true,
                  isCustumExpandCtlShow: true,
                  isfullscreenControlShow: false
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "wrapper" }, [
          _c(
            "div",
            { staticClass: "spinner" },
            [
              _vm.isClimbCountLoading
                ? _c("b-spinner", {
                    staticClass: "mt-5 mb-5",
                    attrs: { variant: "primary", label: "Loading..." }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.isLogin && !_vm.isClimbCountLoading
            ? _c("div", { attrs: { id: "climbing-count" } }, [
                _vm.falseInfo
                  ? _c("div", { staticClass: "text-center my-5" }, [
                      _vm._v("情報の取得に失敗しました")
                    ])
                  : _vm._e(),
                !_vm.falseInfo
                  ? _c(
                      "div",
                      {
                        attrs: { id: "climbing-count-wrapper" },
                        on: {
                          click: function($event) {
                            return _vm.callRouterPush()
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "div" }, [
                          _c("div", { staticClass: "headline" }, [
                            _vm._v("登攀記録")
                          ]),
                          _c("div", { staticClass: "detail" }, [
                            _c("div", { staticClass: "inner" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("総ルート")
                              ]),
                              _c("div", { staticClass: "count-ranking" }, [
                                _c("div", { staticClass: "count" }, [
                                  _vm._v(
                                    _vm._s(_vm.climbingCount.climbAllRoute)
                                  )
                                ]),
                                _c("div", { staticClass: "ranking" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ranking.climbAllRouteRank > 0 &&
                                        _vm.climbingCount.climbAllRoute > 0
                                        ? _vm.ranking.climbAllRouteRank
                                        : " - "
                                    ) + "位"
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "inner" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("完登ルート")
                              ]),
                              _c("div", { staticClass: "count-ranking" }, [
                                _c("div", { staticClass: "count" }, [
                                  _vm._v(
                                    _vm._s(_vm.climbingCount.climbCompleteRoute)
                                  )
                                ]),
                                _c("div", { staticClass: "ranking" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ranking.climbCompleteRouteRank > 0 &&
                                        _vm.climbingCount.climbCompleteRoute > 0
                                        ? _vm.ranking.climbCompleteRouteRank
                                        : " - "
                                    ) + "位"
                                  )
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "div" }, [
                          _c("div", { staticClass: "headline" }, [
                            _vm._v("チェックイン記録")
                          ]),
                          _c("div", { staticClass: "detail" }, [
                            _c("div", { staticClass: "inner" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("都道府県")
                              ]),
                              _c("div", { staticClass: "count-ranking" }, [
                                _c("div", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.checkinCount.prefectures))
                                ]),
                                _c("div", { staticClass: "ranking" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ranking.checkinPrefecturesRank > 0 &&
                                        _vm.checkinCount.prefectures > 0
                                        ? _vm.ranking.checkinPrefecturesRank
                                        : " - "
                                    ) + "位"
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "inner" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("岩場")
                              ]),
                              _c("div", { staticClass: "count-ranking" }, [
                                _c("div", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.checkinCount.rocky))
                                ]),
                                _c("div", { staticClass: "ranking" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ranking.checkinRockyRank > 0 &&
                                        _vm.checkinCount.rocky > 0
                                        ? _vm.ranking.checkinRockyRank
                                        : " - "
                                    ) + "位"
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "inner" }, [
                              _c("div", { staticClass: "title" }, [
                                _vm._v("岩")
                              ]),
                              _c("div", { staticClass: "count-ranking" }, [
                                _c("div", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.checkinCount.rock))
                                ]),
                                _c("div", { staticClass: "ranking" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ranking.checkinRockRank > 0 &&
                                        _vm.checkinCount.rock > 0
                                        ? _vm.ranking.checkinRockRank
                                        : " - "
                                    ) + "位"
                                  )
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            : !_vm.isLogin
            ? _c("div", { attrs: { id: "rocky-search" } }, [
                _c(
                  "div",
                  { attrs: { id: "rocky-search-wrapper" } },
                  [
                    _c("router-link", { attrs: { to: "/rockyInfo/14" } }, [
                      _c("p", [_vm._v("※岩場情報のサンプルを表示する")])
                    ]),
                    _c(
                      "b-button",
                      {
                        staticClass: "button-blue",
                        attrs: { id: "rocky-search-button" },
                        on: {
                          click: function($event) {
                            return _vm.callSearchView()
                          }
                        }
                      },
                      [
                        _c("span", [_c("i", { staticClass: "fas fa-search" })]),
                        _vm._v(" 岩場を探す")
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "b-block", attrs: { id: "yamalab-blog" } }, [
            _c("div", { staticClass: "row no-gutters" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "col-6 col-md-5 banner-box p-2" },
                [
                  _vm._l(_vm.blogData, function(item) {
                    return _c("div", { staticClass: "my-md-2 my-1" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.getBlogLink(item.date.replace(/-/g, "")),
                            target: "_blank",
                            rel: "noopener"
                          }
                        },
                        [
                          _c("div", { staticClass: "d-flex" }, [
                            _c("div", [
                              _c("img", {
                                staticClass: "blog-rocky-img",
                                attrs: { src: _vm.makeImgPath(item.imagePath) }
                              })
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-white pl-1 banner-text5 flex-grow-1"
                              },
                              [
                                _c("div", { staticClass: "banner-text3" }, [
                                  _c(
                                    "div",
                                    { staticClass: "banner-text5 text-white" },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                ]),
                                _c("div", { staticClass: "banner-text4" }, [
                                  _c("div", [
                                    _vm._v(_vm._s(item.date.replace(/-/g, "/")))
                                  ])
                                ])
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  }),
                  _vm._m(1)
                ],
                2
              )
            ])
          ]),
          _c(
            "div",
            { attrs: { id: "recommend" } },
            [
              _c("div", { staticClass: "header-title" }, [
                _vm._v("おすすめ岩場")
              ]),
              _c(
                "carousel",
                {
                  attrs: {
                    "per-page-custom": [[100, 3], [768, 4]],
                    autoplay: false,
                    "navigation-enabled": false,
                    "pagination-enabled": false,
                    "navigation-prev-label": "〈",
                    "navigation-next-label": "〉",
                    speed: 1000
                  }
                },
                _vm._l(this.recommendList, function(rocky) {
                  return _c("slide", [
                    _c(
                      "div",
                      {
                        staticClass: "thumbnail-wrapper",
                        on: {
                          click: function($event) {
                            return _vm.callRockyInfo(rocky.rockyId)
                          }
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "slide-img",
                          attrs: {
                            src: _vm.getRockyImgThumbPath(rocky.imgPath),
                            alt: rocky.rockyName
                          }
                        }),
                        _c("div", { staticClass: "thumbnail-info" }, [
                          _c("div", { staticClass: "rocky-nameKana" }, [
                            _vm._v(_vm._s(rocky.nameKana))
                          ]),
                          _c("div", { staticClass: "rocky-name" }, [
                            _vm._v(_vm._s(rocky.rockyName))
                          ])
                        ])
                      ]
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-6 col-md-7 text-white banner-title pl-1 pl-md-3" },
      [
        _c("div", { staticClass: "banner-text1" }, [_vm._v("管理人ブログ")]),
        _c("div", { staticClass: "banner-text2 mt-1" }, [
          _vm._v("トポの追加や、岩場の最新情報をお届け")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-right banner-text3" }, [
      _c(
        "a",
        {
          staticClass: "text-white",
          attrs: { href: "/blog", target: "_blank", rel: "noopener" }
        },
        [_vm._v("more >")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }